h1 {
  margin-top: 0;
}

.fixed-on-scroll {
  position: initial;

  &.fixed {
    position: fixed;
    top: 0;
  }
}

.quiz-start-link {
  background-color: color(branding, green);
  color: color(branding, grey-extra-light);
  text-decoration: none;
  font-family: $khand;
  text-transform: uppercase;
  font-size: 24px;
  padding: 20px 25px 17px;
  display: inline-block;
}

@media all and (max-width: 998px) {

  article.node-page,
  .not-front.page-node h1 {
    width: 100%;
    max-width: 728px;
    margin: 0 auto;
  }
}