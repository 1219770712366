.block.quiz-list {
  max-width: 300px;
  width: 100%;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    background: color(branding, blue);
    padding: 10px 10px 4px;
    text-align: center;
    color: color(branding, grey-extra-light);
    font-size: 24px;
  }

  .view {
    border: 3px solid #2c2f38;
  }

  .views-row {
    border-bottom: 2px solid darken(color(branding, grey-extra-light), 7%);

    a {
      text-decoration: none;
      display: block;
      font-size: 14px;
      padding: 4px 10px 6px;
      -webkit-transition: all 0.1s ease;
      -moz-transition: all 0.1s ease;
      -ms-transition: all 0.1s ease;
      -o-transition: all 0.1s ease;
      transition: all 0.1s ease;

      &:hover,
      &:focus {
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
        transform: translateX(5px);
      }
    }
  }

  @media all and (max-width: 998px) {
    max-width: 728px;
    margin-left: auto;
    margin-right: auto;
  }
}

.sub-nav {
  max-width: 300px;
  width: 100%;
  margin-bottom: 20px;
  overflow: visible;
  float: right;

  h2 {
    margin: 0;
    background: color(branding, blue);
    padding: 10px 10px 4px;
    text-align: center;
    color: color(branding, grey-extra-light);
    font-size: 24px;
  }

  .menu-block-wrapper {
    border: 3px solid color(branding, blue);
  }

  ul {
    width: 100%;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }

  li{
    border-bottom: 2px solid darken(color(branding, grey-extra-light), 7%);
    border-right: 0;
    width: 100%;
    list-style: none;

    &:hover,
    &:focus {
      background-color: transparent;

      a {
        color: color(branding, blue);
      }
    }

    &.last {
      border-bottom: 0;
    }

    &.is-active {
      border-right: 3px solid color(branding, blue);
    }

    a {
      text-decoration: none;
      display: block;
      font-size: 14px;
      padding: 6px 10px 2px;
      -webkit-transition: all 0.1s ease;
      -moz-transition: all 0.1s ease;
      -ms-transition: all 0.1s ease;
      -o-transition: all 0.1s ease;
      transition: all 0.1s ease;

      &:hover,
      &:focus {
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
        transform: translateX(5px);
      }
    }
  }

  @media all and (max-width: 998px) {
    max-width: 728px;
    margin-left: auto;
    float: none;
    margin-right: auto;
  }
}
