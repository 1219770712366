.node-type-armed-service-landing-page {

  .region-full-width-hero {
    max-height: 600px;
  }

  .content-wrapper {
    background: url("../images/camo-bg.jpg");
  }

  main {
    position: relative;
    top: -200px;
    margin-bottom: -200px;
  }

  .landing-page-article {
    max-width: 728px;
    width: 100%;
    padding: 40px 0;
  }
  
  @media all and (max-width: 1100px) {
    float: none;
    clear: both;
    width: 100%;

    .quiz-buttons {
      .quiz-cta-row {
        margin: 0 auto;
      }
    }

    main {
      top: -100px;
      margin-bottom: -100px;
    }
  }

  @media all and (max-width: 765px) {
    .quiz-buttons {
      .quiz-cta-row {
        width: 100%;

        .quiz-cta,
        .quiz-cta:nth-of-type(odd) {
          margin: 0 auto 15px;
          float: none;
        }
      }
    }

    main {
      top: -20px;
      margin-bottom: -20px;
    }
  }
}