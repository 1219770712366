.region-full-width-hero {
  max-height: 200px;
  overflow: hidden;

  .block .header-title {
    position: absolute;
    text-align: center;
    width: 100%;
    font-weight: 100;
    font-family: $khand;
    font-size: 48px;
    text-transform: uppercase;
    color: color(white);
    margin-top: 50px;

    .bold{
      font-weight: 900;
    }

    a {
      color: color(white);
      text-decoration: none;
    }
  }

  .block img {
    width: 100%;
  }

  @media all and (max-width: 600px) {
    .block .header-title {
      font-size: 24px;
    }
  }
}