.quiz-cta-row{
  width: 728px;
  @include clearfix;
}

.quiz-cta{
  width: 336px;
  height: 280px;
  background-color: color(branding, blue);
  padding: 25px;
  text-align: center;
  float: left;
  margin-bottom: 56px;
  position: relative;

  &:nth-of-type(odd){
    margin-right: 56px;
  }
}

.quiz-cta_heading{
  font-size: 24px;
  font-weight: 500;
  font-family: $khand;
  line-height: 24px;
  color: color(branding, grey-extra-light);
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.quiz-cta_subheading{
  font-size: 16px;
  font-weight: 300;
  font-family: $khand;
  line-height: 16px;
  color: color(branding, grey-extra-light);
  margin-top: 0;
}

.quiz-cta_icon{
  height: 60px;
  display: block;
  margin: 0 auto;
}

.quiz-cta_button{
  transition: background-color 0.3s ease;
  background-color: color(branding, green);
  font-family: $khand;
  font-weight: 900;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  display: block;
  color: color(branding, grey-extra-light);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 11px 10px 8px;
  position: absolute;
  width: calc(100% - 50px);
  bottom: 25px;

  &:hover,
  &:focus,
  &:active{
    background-color: darken(color(branding, green), 7%);
  }
}