html, body {
  overflow-x: hidden;
  overflow-y: auto;
}

.not-logged-in.page-node-take .tabs,
.not-logged-in.node-type-quiz .tabs {
  display: none;
}

.page-node-take {
  h1 {
    text-align: center;
  }

  .hero-wrapper {
    max-height: 200px;
    overflow: hidden;
  }

  .countdown {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    color: color(branding, grey-extra-light);
    background-color: color(branding, blue);
    font-size: 30px;
    font-family: $khand;
    font-weight: bold;
    border: 0;
    padding: 12px 10px 10px;

    &.fixed {
      position: fixed;
      z-index: 3;
    }
  }

  .reset-quiz {
    font-size: 11px;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 0 10px;

    a {
      text-decoration: none;
    }
  }

  @media all and (max-width: 1100px) {
    main {
      @include zen-grid-item(1,1);
    }

    aside {
      display: none;
    }

    #quiz-question-answering-form,
    #quiz-progress {
      margin-left: auto;
      margin-right: auto;
      float: none;
      clear: both;
    }

    .quiz-header-ad,
    .quiz-footer-ad {
      width: 728px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media all and (max-width: 554px) {
    main {
      padding-top: 40px;
    }
  }
}

#quiz-question-answering-form,
#quiz-progress {
  float: left;
  width: 100%;
  max-width: 728px;
  text-align: center;
}

#quiz-progress {
  font-weight: 900;
  font-family: $raleway;
  font-style: normal;
  font-size: 16px;
  margin-top: 25px;
}

.quiz-question-multichoice {
  > .form-type-radios {
    display: none;
  }

  h2 {
    display: none;
  }
}

#quiz-question-answering-form {
  float: none;
  margin-bottom: 15px;

  table {
    margin-bottom: 0;
  }

  tbody {
    border: 0;
  }

  .multichoice-row {
    width: 100%;
    cursor: pointer;
    border: 1px solid color(branding, blue);
    margin-bottom: 15px;
    display: block;
    padding: 0;

    &.selected td {
      background-color: color(branding, blue);
      color: color(branding, grey-extra-light);
    }

    td:first-of-type {
      display: none;
    }

    td:last-of-type {
      width: 100%;
      text-align: center;
      display: block;
      padding: 12px 20px;

      p {
        margin: 0;
      }
    }
  }

  .form-actions {
    @include clearfix;

    input[value="Next"],
    input[value="Back"],
    input[value="Finish"] {
      background-color: color(branding, green);
      border: 0;
      width: 70%;
      float: right;
      padding: 20px;
      font-family: $raleway;
      font-weight: 900;
      color: color(branding, grey-extra-light);
      font-size: 26px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    input[value="Back"] {
      width: 28%;
      width: calc(30% - 15px);
      float: left;
      background-color: #39691b;
    }

    @media all and (max-width: 600px) {
      input[value="Next"],
      input[value="Back"],
      input[value="Finish"],
      input[value="Back"] {
        font-size: 18px;
      }
    }
  }

  svg,
  .svg {
    height: 100px !important;
  }

  .cls-1,
  .st0,
  .cls-2 {
    stroke: color(branding, blue) !important;
    fill: transparent !important;
  }

  .selected .cls-1,
  .selected .cls-2,
  .selected .st0 {
    stroke: color(white) !important;
  }
}

.page-results {

  .result {
    text-align: center;
  }

  .form-wrapper {
    padding: 0;
    border: 0;
    margin: 0;
  }

  .fieldset-wrapper {
    @include clearfix;
    max-width: 728px;
    width: 100%;
    float: left;

    .result-wrapper {
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      text-align: center;
      position: relative;
      margin-bottom: 10px;

      &.incorrect .is-correct-wrapper,
      &.correct .is-correct-wrapper {
        display: inline-block;
        padding: 10px 30px;
        background: #8e3131;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        top: -20px;
      }

      &.correct .is-correct-wrapper {
        background: color(branding, green);
      }

      .question {
        font-weight: bold;
        margin-bottom: 15px;
      }

      .choices {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 10px;
          border: 1px solid;
          margin: 0 auto 10px;
          width: 80%;
          position: relative;

          &.chosen,
          &.correct {
            .icon {
              position: absolute;
              left: -10%;
              font-size: 24px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &.chosen {
            color: #8e3131;
            background-color: rgba(142,49,49,0.12);

            .icon {
              color: #8e3131;
            }

            .cls-1,
            .st0,
            .cls-2 {
              stroke: #8e3131 !important;
            }
          }

          &.correct,
          &.correct.chosen {
            background-color: rgba(79, 147, 38, 0.12);
            color: color(branding, green);

            .icon {
              color: color(branding, green);
            }

            .cls-1,
            .st0,
            .cls-2 {
              stroke: color(branding, green) !important;
            }
          }

          &.correct.was-not-answered {
            background-color: transparent;
            color: color(branding, blue);
          }

          p {
            margin: 0;
          }
        }
      }

      .question-feedback {
        margin: 15px 0;
      }
    }
  }

  .print-wrapper {
    max-width: 728px;
    width: 100%;
  }

  .print-results {
    width: 100%;
    background: color(branding, blue);
    border: 0;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  svg,
  .svg {
    height: 100px !important;
  }

  .cls-1,
  .st0,
  .cls-2 {
    stroke: color(branding, blue) !important;
    fill: transparent !important;
  }

  .selected .cls-1,
  .selected .cls-2,
  .selected .st0 {
    stroke: color(white) !important;
  }

  @media all and (max-width: 998px) {
    .fieldset-wrapper,
    .print-wrapper {
      float: none;
      margin: 0 auto;
      text-align: center;
    }

    .print-wrapper {
      margin-bottom: 20px;
    }

    .quiz-sidebar-ad {
      display: none;
    }
  }
}