.footer-wrapper{
  background-color: color(branding, blue);
  margin-top: 150px;
  padding: 20px 10px 80px;
  position: relative;

  .block{
    color: color(branding, grey-extra-light);
    font-family: $khand;
    font-weight: 100;
    text-align: center;
    font-size: 14px;

    p{
      margin: 0;
    }
  }

  .ship-icon{
    position: absolute;
    top: -99px;
    left: 0;
    right: 0;
  }
}