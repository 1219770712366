.main-nav{
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  text-align: center;
  
  @media all and (max-width: 600px) {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    z-index: 1;
    background-color: color(branding, blue);
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &.open{
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
    }
  }
}

.main-nav .menu-name-main-menu {
  ul {
    display: inline-block;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0;
  }

  li {
    list-style: none;
    display: inline-block;
    position: relative;
    margin-right: -4px;
    border-right: 2px solid color(branding, blue);

    &:last-of-type {
      margin-right: 0;
      border-right: 0;
    }

    &:hover,
    &:focus {
      background-color: color(branding, blue);

      a {
        color: #fff;
      }

      ul {
        display: block;
        z-index: 3;
      }
    }

    a {
      font-weight: 700;
      font-family: $khand;
      font-size: 18px;
      text-decoration: none;
      display: inline-block;
      padding: 10px 20px;
      color: color(link);
    }

    &.menu-mlid-719 {
      &:hover,
      &:focus {
        background-color: color(branding, blue);

        a {
          color: #fff;
        }

        ul {
          display: block;
          z-index: 3;
        }
      }

      ul {
        width: 450px;

        li {
          list-style: none;
          display: block;
          float: left;
          width: 150px;
          margin-right: 0;

          // Forgive me oh mighty CSS god for my use of !important. These styles are overriding
          // the inline styles produced by the menu icon module
          .menu_icon {
            transition: all .2s ease;
            -webkit-transition: all .2s ease;
            -moz-transition: all .2s ease;
            background-position: center bottom 40px !important;
            background-color: color(branding, blue) !important;
            background-size: 30px !important;
            height: 150px !important;
            width: 150px !important;
            display: block !important;
            color: #e8e8e8 !important;
            font-weight: normal !important;
            text-decoration: none !important;
            line-height: 16px !important;
            padding-top: 20px !important;
            font-family: $khand !important;
            padding-right: 10px !important;
            padding-left: 10px !important;
            text-align: center !important;

            &:hover,
            &:focus {
              background-color: darken(color(branding, blue), 7%) !important;
              background-position: center bottom 50px !important;
            }
          }
        }
      }
    }

    ul {
      padding: 0;
      display: none;
      position: absolute;
      box-shadow: 0px 3px 4px 2px rgba(0,0,0,0.3);

      li {

        max-width: 100%;
        width: 272px;
        text-align: left;
        margin-right: 0;

        a {
          background-color: color(branding, blue);
          color: color(branding, grey-extra-light);
          font-weight: 400;
          font-size: 14px;
          width: 100%;
        }
      }
    }

  }

  @media all and (max-width: 600px) {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    overflow: scroll;

    > .menu {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 140px;
      background-color: transparent;

      > li {
        display: block;
        width: 100%;
        margin: 0;
        border-bottom: 2px solid darken(color(branding, blue), 7%);

        >a {
          width: 100%;
          background: #e8e8e8;
          color: #2c2f38 !important;
        }

        &.menu-mlid-719 {
          border-top: 2px solid darken(color(branding, blue), 7%);
          overflow-x: scroll;
          -webkit-overflow-scrolling: touch;

          a {
            position: fixed;
            top: 100px;
            left: 0;
            right: 0;
          }

          ul {
            position: relative;
            display: block;
            width: 100%;
            @include clearfix;

            li {
              display: block;
              float: left;
              width: 33.33333%;
              border: 0;
              position: static;

              &:hover,
              &:focus {
                a {
                  background-position: center bottom 20px !important;
                }
              }

              a {
                padding-top: 10px !important;
                background-position: center bottom 20px !important;
                background-size: 20px !important;
                font-size: 12px !important;
                height: 90px !important;
                width: 100% !important;
                position: static !important;
              }
            }
          }
        }

        a {
          color: #fff;
        }

        ul {
          position: relative;
          display: block;
          @include clearfix;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          background-color: color(branding, blue);

          li {
            display: block;
            width: 100%;
            border: 0;
            position: static;
            border-top: 1px solid darken(color(branding, blue), 7%);

            a {
              font-size: 12px;
              width: 100%;
              background-color: #2c2f38;
              color: #e8e8e8;
            }
          }
        }

      }
    }
  }
}

.mobile-toggle-wrapper {
  position: fixed;
  top: 25px;
  right: 10px;
  width: 61px;
  display: none;
  z-index: 10;
  padding: 10px 10px 4px;
  background-color: color(branding, blue);
  cursor: pointer;
  
  .top,
  .middle,
  .bottom {
    width: 100%;
    height: 2px;
    background: white;
    display: block;
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
  }

  .middle {
    margin: 10px 0;
  }

  .mobile-toggle {
    font-family: $khand;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    
    &.open {
      .top {
        -webkit-transform: translateY(8px);
        -moz-transform: translateY(8px);
        -ms-transform: translateY(8px);
        -o-transform: translateY(8px);
        transform: translateY(8px);
      }
      
      .bottom {
        -webkit-transform: translateY(-8px);
        -moz-transform: translateY(-8px);
        -ms-transform: translateY(-8px);
        -o-transform: translateY(-8px);
        transform: translateY(-8px);
      }
    }
  }

  @media all and (max-width: 600px) {
    display: block;
  }
}